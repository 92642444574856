import { render, staticRenderFns } from "./ChallengeWorkouts.vue?vue&type=template&id=6d794a16&scoped=true&"
import script from "./ChallengeWorkouts.vue?vue&type=script&lang=js&"
export * from "./ChallengeWorkouts.vue?vue&type=script&lang=js&"
import style0 from "./ChallengeWorkouts.vue?vue&type=style&index=0&id=6d794a16&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d794a16",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
installComponents(component, {VBtn,VCard,VCardText,VCardTitle,VChip,VCol,VDivider})
