<template>
  <div class="bbr-workouts--search-exercise-input">
    <v-combobox
      v-model="form.workout"
      :label="label"
      :readonly="false"
      :items="workouts"
      :error-messages="errors"
      :hide-details="!hasErrors"
      :search-input.sync="search"
      :return-object="returnObject"
      :hide-no-data="hideNoData"
      :clearable="clearable && search && !form.$busy"
      @change="onWorkoutSelect"
      item-text="title"
      item-value="id"
      no-filter
      outlined
      flat
    >
      <template v-if="form.$busy" v-slot:append>
        <v-fade-transition leave-absolute>
          <v-progress-circular size="24" color="primary" indeterminate />
        </v-fade-transition>
      </template>

      <template v-slot:item="data">
        <v-list-item-content>
          <v-list-item-title>
            {{ data.item.title }}
          </v-list-item-title>
          <v-list-item-subtitle v-if="withTags">
            Tags: {{ data.item.tags | readable }}
          </v-list-item-subtitle>
          <v-list-item-subtitle v-if="withDescription">
            {{ data.item.description }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </v-combobox>
  </div>
</template>

<script>
import Workout from '@/models/Workout'
import Form from '@/utils/form'

import { isEmpty, debounce } from 'lodash'

export default {
  name: 'SearchWorkoutsInput',

  props: {
    value: {
      type: [Number, Object],
    },

    label: {
      type: String,
      default: 'Search Workout',
    },

    completed: {
      type: Boolean,
      default: false,
    },

    errors: {
      type: [Object, Array],
      default: () => {
        return []
      },
    },

    clearable: {
      type: Boolean,
      default: false,
    },

    workoutOnly: {
      type: Boolean,
      default: false,
    },

    extraOnly: {
      type: Boolean,
      default: false,
    },

    returnObject: {},

    withTags: {
      type: Boolean,
      default: false,
    },

    withDescription: {
      type: Boolean,
      default: false,
    },

    trainerId: {
      required: false,
    },
  },

  data() {
    return {
      search: '',
      workouts: [],
      form: new Form({
        workout: null,
      }),
    }
  },

  computed: {
    hasErrors() {
      return !!this.errors.length
    },

    hasSearch() {
      return !isEmpty(this.search)
    },

    hideNoData() {
      return !this.hasSearch && !!this.workouts.length
    },

    hasTrainerId() {
      return isEmpty(this.trainerId)
    },
  },

  methods: {
    searchExercise: debounce(function() {
      this.workouts = []

      this.fetchWorkouts().then(({ data }) => {
        this.form.$busy = false
        this.workouts = data
      })
    }, 250),

    async fetchWorkouts() {
      let workout = await Workout.where('search', this.search)

      if (this.completed) {
        workout.where('completed', this.completed)
      }

      if (this.trainerId) {
        workout.where('trainer', this.trainerId)
      }

      return workout
        .include(['tags'])
        .orderBy('title')
        .params({ limit: 200 })
        .page(1)
        .get()
    },

    onWorkoutSelect(workout = {}) {
      if (workout) {
        this.$emit('input', workout.id)
      }

      this.$emit('select', workout)
    },
  },

  watch: {
    search(val) {
      if (val) {
        this.loading = true
        this.form.$busy = true

        this.searchExercise()
      }
    },

    value(value) {
      if (!value) {
        this.search = ''
        this.form.workout = null
      }
    },
  },
}
</script>
