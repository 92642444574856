<template>
  <div class="bbr-challenges--workout-form">
    <v-row>
      <v-col cols="12" sm="3">
        <trainer-search-input
          v-model="form.trainer_id"
          label="Workout Trainer"
          :error-messages="form.$getError('trainer_id')"
          :default-value="challenge.trainer"
          clearable
          outlined
          flat
        />
      </v-col>

      <v-col cols="12" sm="3">
        <search-workout
          v-model="form.workout_id"
          :trainer-id="form.trainer_id"
          :errors="form.$getError('workout_id')"
          @select="addWorkout"
          with-description
          return-object
          workout-only
          with-tags
          completed
        />
      </v-col>

      <v-col cols="12" sm="1">
        <v-select
          v-model="form.week"
          :items="weeks"
          :error-messages="form.$getError('week')"
          label="Week"
          item-value="id"
          item-text="name"
          :hide-details="!form.$getError('week')"
          outlined
          flat
        />
      </v-col>

      <v-col cols="12" sm="2">
        <v-select
          v-model="form.day"
          :items="days"
          :error-messages="form.$getError('day')"
          label="Day"
          item-value="id"
          item-text="name"
          :hide-details="!form.$getError('day')"
          outlined
          flat
        />
      </v-col>

      <v-col cols="12" sm="3">
        <v-btn color="primary" @click="addWorkoutToChallenge" block x-large>
          <v-icon class="mr-3"> {{ icons.add }} </v-icon>
          Add Workout
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import SearchWorkout from '@/components/elements/workouts/SearchWorkoutsInput'
import TrainerSearchInput from '@/components/elements/trainers/TrainerSearchInput'
import Workout from '@/models/Workout'
import Form from '@/utils/form'

import { mapState, mapMutations } from 'vuex'
import { map, values } from 'lodash'
import { mdiPlus } from '@mdi/js'

export default {
  name: 'ProgramWorkoutForm',

  components: {
    SearchWorkout,
    TrainerSearchInput,
  },

  data() {
    return {
      workout: {},
      challenge: {},

      days: [
        { id: 0, name: 'SUNDAY' },
        { id: 1, name: 'MONDAY' },
        { id: 2, name: 'TUESDAY' },
        { id: 3, name: 'WEDNESDAY' },
        { id: 4, name: 'THURSDAY' },
        { id: 5, name: 'FRIDAY' },
        { id: 6, name: 'SATURDAY' },
      ],

      icons: {
        add: mdiPlus,
      },

      form: new Form({
        day: null,
        week: null,
        is_deload: null,
        workout_id: null,
        trainer_id: null,
      }),
    }
  },

  created() {
    this.challenge = this.selectedChallenge
  },

  computed: {
    ...mapState({
      selectedChallenge: (state) => state.challenges.selectedChallenge,
    }),

    weeks() {
      return map([...Array(this.challenge.week_count).keys()], (week) => {
        let _week = week + 1

        return {
          id: _week,
          name: _week,
        }
      })
    },
  },

  methods: {
    ...mapMutations({
      setChallengeWorkout: 'challenges/setSelectedChallengeWorkout',
    }),

    addWorkout(workout) {
      this.workout = workout || {}
    },

    async addWorkoutToChallenge() {
      this.form.$busy = true
      this.form.$clearErrors()

      try {
        let workout = new Workout(this.form).for(this.selectedChallenge)
        let data = await workout.save()

        this.setChallengeWorkout(values(data))

        this.form.$reset()
      } catch ({ response }) {
        this.form.$timeout(() => {
          this.form.$busy = false

          if (response && response.status === 422) {
            this.form.$setErrors(response.data.errors)
          }
        })
      }
    },
  },

  watch: {
    selectedChallenge(challenge) {
      this.challenge = challenge
      if (challenge.trainer) {
        this.form.trainer_id = challenge.trainer.id
      }
    },

    errors(errors) {
      this.form.$setErrors(errors)
    },
  },
}
</script>
