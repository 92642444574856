<template>
  <div class="bbr-challenges--workouts">
    <div v-if="challengeWorkouts.length" class="my-5">
      <v-card
        v-for="(grouping, groupIndex) in groupedWorkouts"
        :key="groupIndex"
        class="mb-8"
        outlined
      >
        <v-card-title class="primary lighten-4">
          Week {{ groupIndex }}
        </v-card-title>

        <template v-for="(workout, workoutIndex) in grouping">
          <v-card-text class="pa-0" :key="workoutIndex">
            <router-link
              class="row clickable pa-5 pt-7 mx-0"
              :to="{
                name: 'workout.details',
                params: { id: workout.id },
                meta: { stage: 1 },
              }"
            >
              <v-col cols="2">
                <v-chip
                  :color="getDayColor(workout.day)"
                  text-color="white"
                  small
                >
                  {{ getDay(workout.day) }}
                </v-chip>
              </v-col>
              <v-col cols="2"> {{ workout.title }} </v-col>
              <v-col cols="2">
                <span
                  v-for="(trainingProgram, index) in workout.training_programs"
                  :key="trainingProgram.id"
                >
                  <span v-if="index !== 0">, </span>
                  {{ trainingProgram.name }}
                </span>
              </v-col>
              <v-col cols="3"> {{ workout.description }} </v-col>
              <v-col cols="3">
                <v-btn
                  class="float-right"
                  color="secondary"
                  :loading="loading"
                  @click="removeWorkout($event, workout)"
                  text
                >
                  Remove
                </v-btn>
              </v-col>
            </router-link>
          </v-card-text>

          <v-divider :key="`divider-${workoutIndex}`" />
        </template>
      </v-card>
    </div>

    <no-list v-else details="No Workouts" />

    <confirm-dialog ref="confirmDelete" />
  </div>
</template>

<script>
import ConfirmDialog from '@/components/modals/ConfirmDialog'
import NoList from '@/components/elements/NoList'
import Workout from '@/models/Workout'

import { map, sortBy, groupBy, mapValues } from 'lodash'
import { mapGetters, mapMutations, mapState } from 'vuex'

export default {
  name: 'ProgramWorkouts',

  components: {
    NoList,
    ConfirmDialog,
  },

  data() {
    return {
      loading: false,
    }
  },

  computed: {
    ...mapState({
      selectedChallenge: (state) => state.challenges.selectedChallenge,
    }),

    ...mapGetters({
      challengeWorkouts: 'challenges/getSelectedChallengeWorkout',
    }),

    groupedWorkouts() {
      let _workouts = map(this.challengeWorkouts, (workout) => {
        return {
          ...workout,
          ...{
            day: workout.program_workout.day,
            week: workout.program_workout.week,
            program_workout_id: workout.program_workout.id,
          },
        }
      })

      let _groupedByWeek = groupBy(_workouts, 'week')

      return mapValues(_groupedByWeek, (workout, index) => {
        return sortBy(workout, ['day'])
      })
    },
  },

  methods: {
    ...mapMutations({
      removeChallengeWorkout: 'challenges/removeChallengeWorkout',
    }),

    getDay(index) {
      let days = [
        'SUNDAY',
        'MONDAY',
        'TUESDAY',
        'WEDNESDAY',
        'THURSDAY',
        'FRIDAY',
        'SATURDAY',
      ]

      return days[index]
    },

    getDayColor(index) {
      let colors = [
        'blue-grey',
        'blue',
        'orange',
        'pink',
        'green',
        'purple',
        'teal',
      ]

      return colors[index]
    },

    async removeWorkout(event, workout) {
      event.preventDefault()

      const confirm = await this.$refs.confirmDelete.open(
        'Remove workout',
        'Are you sure you want to remove this workout?'
      )

      if (!confirm) return

      this.loading = true

      const programWorkout = new Workout({ id: workout.program_workout_id })

      await programWorkout.for(this.selectedChallenge).delete()

      this.removeChallengeWorkout(workout)

      this.loading = false
    },
  },
}
</script>

<style lang="scss" scoped>
.border-bottom {
  border: solid;
  border-width: 0 0 thin 0;
  border-color: rgba(0, 0, 0, 0.12) !important;
}

.clickable {
  text-decoration: none !important;
  color: rgba(0, 0, 0, 0.6);
}
</style>
