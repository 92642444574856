<template>
  <div class="bbr-challenge--new-workouts">
    <v-row>
      <v-col cols="12" sm="10">
        <div class="d-flex justify-space-between align-center mb-4">
          <h2 class="mr-auto">Challenge Workouts</h2>
          <v-btn
            v-if="!isCompleted"
            class="primary--text mr-5"
            @click="saveForLater"
            :loading="loading"
            depressed
            text
          >
            Save & Finish Later
          </v-btn>

          <v-btn
            v-if="hasDeload"
            class="bg-primary-gradient primary next-button px-12"
            @click="saveAndNext"
            :loading="loading"
            :disabled="false"
            :text="true"
            depressed
          >
            <span> Next </span>
            <v-icon> {{ icons.next }} </v-icon>
          </v-btn>

          <v-btn
            v-else
            class="bg-primary-gradient primary next-button px-8"
            @click="saveAndComplete"
            :loading="loading"
            :disabled="false"
            :text="true"
            depressed
          >
            <span v-if="!isCompleted"> Complete Challenge </span>
            <span v-else> Save Changes </span>
            <v-icon> {{ icons.check }} </v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-row class="mt-8">
      <v-col cols="12" sm="10">
        <challenge-workout-form />
        <challenge-workouts class="mt-12" />
      </v-col>
    </v-row>

    <v-toast ref="errorToast" />
    <confirm-dialog ref="confirmDelete" />
  </div>
</template>

<script>
import ChallengeWorkoutForm from '@/components/forms/challenges/ChallengeWorkoutForm'
import ChallengeWorkouts from '@/components/forms/challenges/ChallengeWorkouts'
import ChallengeWorkoutsMixin from '../mixins/ChallengeWorkoutsMixin'
import ConfirmDialog from '@/components/modals/ConfirmDialog'
import VToast from '@/components/elements/Toast'
import Challenge from '@/models/Challenge'
import Event from '@/services/eventBus'

export default {
  name: 'NewChallengeWorkouts',

  mixins: [ChallengeWorkoutsMixin],

  components: {
    ChallengeWorkoutForm,
    ChallengeWorkouts,
    ConfirmDialog,
    VToast,
  },

  data() {
    return {
      addWorkout: false,
      workouts: [],
    }
  },

  created() {
    this.fetchChallengeWorkouts()
  },

  methods: {
    saveForLater() {
      this.loading = true

      Event.$emit('save-challenge-as-draft')

      this.timeOut(() => {
        this.loading = false
        this.$router.push({ name: 'draft.challenges' })
      })
    },

    async saveAndNext() {
      this.loading = true

      setTimeout(() => {
        if (this.isCompleted) {
          this.$router.push({
            name: 'challenge.deload.workouts',
            params: { id: this.$attrs.id },
          })
        } else {
          this.$router.push({
            name: 'new.challenge.deload.workouts',
            params: { id: this.$attrs.id },
          })
        }
      }, 600)
    },

    async fetchChallengeWorkouts(challenge) {
      challenge = challenge instanceof Challenge ? challenge.id : this.$attrs.id

      let _challenge = new Challenge({ id: challenge })

      let { data } = await _challenge.workouts().get()

      this.workouts = data
    },
  },
}
</script>
